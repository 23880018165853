body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url('../public/bg.webp');
  background-size: cover;
  background-position: bottom;
  background-attachment: fixed;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --common: white;
    --common-opacity-10: rgba(255, 255, 255, 0.1);
    --common-opacity-20: rgba(255, 255, 255, 0.2);
  --uncommon: rgb(25, 135, 84);
    --uncommon-opacity-10: rgba(25, 135, 84, 0.1);
    --uncommon-opacity-20: rgba(25, 135, 84, 0.2);
  --rare: rgb(13, 110, 253);
    --rare-opacity-10: rgba(13, 110, 253, 0.1);
    --rare-opacity-20: rgba(13, 110, 253, 0.2);
  --epic: rgb(111, 66, 193);
    --epic-opacity-10: rgba(111, 66, 193, 0.1);
    --epic-opacity-20: rgba(111, 66, 193, 0.2);
  --exceptional: rgb(220, 53, 69);
    --exceptional-opacity-10: rgba(220, 53, 69, 0.1);
    --exceptional-opacity-20: rgba(220, 53, 69, 0.2);
  --legendary: rgb(255, 193, 7);
    --legendary-opacity-10: rgba(255, 193, 7, 0.1);
    --legendary-opacity-20: rgba(255, 193, 7, 0.2);
  --unique: rgb(214, 51, 132);
    --unique-opacity-10: rgba(214, 51, 132, 0.1);
    --unique-opacity-20: rgba(214, 51, 132, 0.2);
}

.bg-COMMON {
    background-color: var(--bs-white) !important;
    color: var(--bs-black) !important;
}

.border-COMMON {
    border-color: var(--bs-black) !important;
}

.bg-UNCOMMON {
  background-color: var(--uncommon-opacity-10) !important;
  color: var(--uncommon) !important;
}

.border-UNCOMMON {
  border-color: var(--uncommon) !important;
}

.bg-RARE {
  background-color: var(--rare-opacity-10) !important;
  color: var(--rare) !important;
}

.border-RARE {
  border-color: var(--rare) !important;
}

.bg-EPIC {
  background-color: var(--epic-opacity-10) !important;
  color: var(--epic) !important;
}

.border-EPIC {
  border-color: var(--epic) !important;
}

.bg-EXCEPTIONAL {
  background-color: var(--exceptional-opacity-10) !important;
  color: var(--exceptional) !important;
}

.border-EXCEPTIONAL {
  border-color: var(--exceptional) !important;
}

.bg-LEGENDARY {
  background-color: var(--legendary-opacity-10) !important;
  color: var(--exceptional) !important;
}

.border-LEGENDARY {
  border-color: var(--legendary) !important;
}

.bg-UNIQUE {
  background-color: var(--unique-opacity-10) !important;
  color: var(--unique) !important;
}

.border-UNIQUE {
  border-color: var(--unique) !important;
}




